<template>
  <div>
    <div class="header">
      <img @click="go" src="../assets/z1.png" alt="" />
    </div>
    <div class="card">
      <div class="name">{{ invitationinif.name || "姓名：暂无" }}</div>
      <div class="cxm">沪惠学</div>
      <div class="phone">{{ invitationinif.phone || "手机号：暂无" }}</div>
      <div class="site">上海市</div>
      <img :src="invitationinif.picture" class="userimg" alt="" />
      <div class="id">id:{{ invitationinif.id }}</div>
    </div>
    <div class="tablist">
      <div class="list1">
        <div class="item1">
          <div class="num">
            {{ invitationinif.sale ? invitationinif.sale.consulting : "90" }}
          </div>
          <div>咨询量</div>
        </div>
        <div class="item1">
          <div class="num">
            {{ invitationinif.sale ? invitationinif.sale.recommend : "60" }}
          </div>
          <div>推荐量</div>
        </div>
        <div class="item1">
          <div class="num">
            {{ invitationinif.sale ? invitationinif.sale.satisfied : "100" }}
          </div>
          <div>满意指数</div>
        </div>
      </div>
      <div class="list2">
        <div class="item2" @click="fx">
          <img src="../assets/fx2.jpg" alt="" />
          <div class="b">推荐TA</div>
        </div>
        <a :href="`tel:${invitationinif.phone}`" class="item2">
          <img src="../assets/dh.jpg" alt="" />
          <div class="b">打电话</div>
        </a>
        <div class="item2" @click="show">
          <img src="../assets/wx.png" alt="" />
          <div class="b">加微信</div>
        </div>
        <div class="item2" @click="ts">
          <img src="../assets/jy1.jpg" alt="" />
          <div class="b">投诉她</div>
        </div>
      </div>
    </div>

    <img
      v-if="invitationinif.qr_code"
      class="codeimg"
      :src="invitationinif.qr_code"
      alt=""
    />
    <div class="sys" v-if="invitationinif.qr_code">
      微信扫一扫或长按识别可直接添加
    </div>
    <div class="tac" v-if="!invitationinif.qr_code">暂无微信二维码</div>
    <div class="content">
      <span v-html="invitationinif.synopsis || '暂无个人介绍'"></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions(["getconsultingInc", "getrecommendInc"]),
    go() {
      this.$router.go(-1);
    },
    //复制
    copyText(text, callback) {
      // text: 要复制的内容， callback: 回调
      var tag = document.createElement("input");
      tag.setAttribute("id", "cp_hgz_input");
      tag.value = text;
      document.getElementsByTagName("body")[0].appendChild(tag);
      document.getElementById("cp_hgz_input").select();
      document.execCommand("copy");
      document.getElementById("cp_hgz_input").remove();
      if (callback) {
        callback(text);
      }
    },

    show() {
      let that = this;
      this.$dialog
        .confirm({
          title: "",
          message: `微信号：${this.invitationinif.wx || "暂无"}`,
        })
        .then(() => {
          // on confirm
          that.copyText(that.invitationinif.wx, function (e) {
            that.$toast.success("复制成功");
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    fx() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信浏览器
        this.$toast("点击右上角...进行分享");
      } else {
        //非微信浏览器
        this.$toast("请在手机微信端运行");
      }
    },
    ts() {
      this.$router.push({
        path: "/suggest",
        query: {
          is_type: 2,
        },
      });
    },
  },
  mounted() {
    this.getconsultingInc({
      member_id: this.invitationinif.id,
    });
    this.getrecommendInc({
      member_id: this.invitationinif.id,
    });
  },
};
</script>

<style lang='scss' scoped>
.header {
  position: relative;
  height: 4.5rem;
  background: url("../assets/userimg.jpg") no-repeat;
  background-size: 100%;
  img {
    position: absolute;
    width: 0.4rem;
    left: 0.3rem;
    top: 0.8rem;
  }
}
.card {
  position: relative;
  padding: 0.59rem 0.49rem;
  box-sizing: border-box;
  z-index: 100;
  margin-left: 0.28rem;
  margin-top: -2.5rem;
  width: 6.95rem;
  height: 3.92rem;
  background: url("../assets/zy.png");
  background-size: 100%;
  border-radius: 0.2rem;
  .name {
    font-size: 0.4rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #ffffff;
  }
  .cxm {
    margin-top: 0.1rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .phone {
    margin-top: 1.1rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .site {
    margin-top: 0.1rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
  .userimg {
    position: absolute;
    border-radius: 50%;
    right: 0.52rem;
    top: 0.65rem;
    width: 0.94rem;
    height: 0.94rem;
    border: 0.15rem solid rgba($color: #fff, $alpha: 0.1);
  }
  .id {
    position: absolute;
    right: 0.6rem;
    top: 2.6rem;
    font-size: 0.18rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    transform: rotate(90deg);
  }
}
.tablist {
  overflow: hidden;
  margin-top: -0.1rem;
  margin-left: 0.28rem;
  width: 6.96rem;
  height: 4.1rem;
  background: #ffffff;
  box-shadow: 0rem 0.05rem 0.12rem 0rem rgba(156, 156, 156, 0.23);
  border-radius: 0.1rem;
  .list1 {
    margin-top: 0.3rem;
    padding-bottom: 0.5rem;
    border-bottom: 0.01rem solid #e7e7e7;
    display: flex;
    justify-content: space-around;
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #141f33;
    text-align: center;
    box-sizing: border-box;
    .num {
      font-size: 0.78rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0019ff;
    }
  }
  .list2 {
    display: flex;
    justify-content: space-around;
    .item2 {
      text-align: center;
      img {
        display: block;

        width: 0.6rem;
        height: 0.6rem;
        margin: 0.32rem auto 0.05rem;
      }
      .b {
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141f33;
      }
    }
  }
}
.codeimg {
  display: block;
  width: 2.89rem;
  height: 2.88rem;
  margin: 0.52rem auto 0.18rem;
}
.sys {
  text-align: center;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #141f33;
}
.content {
  padding: 0.46rem 0.46rem 1rem 0.46rem;
  box-sizing: border-box;
  font-size: 0.3rem;
}
</style>